import React from 'react';

import Bloco from './Bloco';
import NewsletterRodape from './NewsletterRodape';

function Rodape() {
    return <>
        <div className="border-top border-dark bg-white">
        {/* <div className="secondary" style={{backgroundColor: '#d2d2d2'}}> */}
        {/* <div className="secondary bg-color2"> */}
            <div className="container" style={{ paddingTop: '10px', paddingBottom: '10px', verticalAlign: 'middle', fontSize: '15px' }}>
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <Bloco id="243" pagina="N"/>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        {/* <Bloco id="243" pagina="N"/> */}
                        <NewsletterRodape/>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12">
                        <Bloco id="244" pagina="N"/>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Rodape;