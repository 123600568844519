import React from 'react';

function Cabecalho(props) {
    const { className, titulo, texto } = props;
    return <>
        <div className={'border-bottom border-dark-subtle mt-3 '+className}>
            <span className="cabecalho ms-2">{titulo}</span>
        </div>
        { texto!==""? <p>{texto}</p> : "" }
    </>
}

export default Cabecalho;