import React from 'react';

function VideoCard(props) {
    return <>
        <div className="col" key={props.video.VID_CODIGO}>
            <div className="card h-100 bg-transparent border-0">
                <div className="pett-card-img">
                    <img className="card-img-top rounded-0" src={props.video.VID_IMAGEMDESTAQUE} alt={props.video.VID_CODIGO} />
                    <div className="pett-card-img-overlay">
                        <a className="stretched-link" href={'/videos/'+props.video.VID_SLUG}><i className="fa fa-fw fa-2x fa-play-circle"></i></a>
                    </div>
                </div>
                <div className="card-body p-2">
                    <p className="text-secondary m-0"><span className="badge bg-secondary bg-opacity-10 text-secondary">{props.video.VID_DATA}</span></p>
                    <h5 className="card-title"><a className="link-secondary " href={'/videos/'+props.video.VID_SLUG}>{props.video.VID_NOME}</a></h5>
                    <span>{props.video.VID_DESCRICAOCURTA}</span>
                </div>
                {/* <div className="card-footer p-0 bg-transparent text-end px-3 d-flex justify-content-between align-items-center">
                    <a className="link-dark" href={'/news/'+props.video.VID_SLUG}>Assistir... </a>
                </div> */}
            </div>
        </div>
    </>
}

export default VideoCard;