import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { Password } from 'primereact/password';

import Api from '../../Api';
import { AuthContext } from '../../Contextos/Auth';
import BarraSuperior from '../../Componentes/BarraSuperior';
import ColunaCentral from '../../Componentes/ColunaCentral';
import Rodape from '../../Componentes/Rodape';
import Cabecalho from '../../Componentes/Cabecalho';

function AlterarSenha() {
    const { logout } = useContext(AuthContext);
    const [ senhaAtual, setSenhaAtual ] = useState('');
    const [ novaSenha1, setNovaSenha1 ] = useState('');
    const [ novaSenha2, setNovaSenha2 ] = useState('');

    async function salvar() {
        if (novaSenha1!==novaSenha2) {
            Swal.fire({title: 'Epaaaa!', text: 'A nova senha não confere com a confirmação da senha.', icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        } else {
            const formData = new FormData();
            formData.set('op','alterarsenha');
            formData.set('senhaatual',senhaAtual || '');
            formData.set('novasenha1',novaSenha1 || '');
            formData.set('novasenha2',novaSenha2 || '');
            const response = await Api.post('usuario/profile.php',formData);
            if (response.data.erro==='N') {
                Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                setSenhaAtual('');
                setNovaSenha1('');
                setNovaSenha2('');
            }
            if (response.data.erro==='S') {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" container="container">
            <Cabecalho titulo="Alterar Senha" texto="Utilize sempre senhas fortes com letras maiúsculas e minúsculas e números." />

            <div className="row mt-5 gx-2 p-inputtext-sm">
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <Password className="w-100" value={senhaAtual} onChange={(e) => setSenhaAtual(e.target.value)} toggleMask />
                        <label htmlFor="senhaAtual">SENHA ATUAL</label>
                    </span>
                </div>
            </div>
            <div className="row gx-2 p-inputtext-sm">
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <Password className="w-100" type="password" value={novaSenha1} onChange={(e) => setNovaSenha1(e.target.value)} toggleMask />
                        <label htmlFor="novaSenha1">NOVA SENHA</label>
                    </span>
                </div>
            </div>
            <div className="row gx-2 p-inputtext-sm mb-4">
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <Password className="w-100" value={novaSenha2} onChange={(e) => setNovaSenha2(e.target.value)} toggleMask />
                        <label htmlFor="novaSenha2">CONFIRME A NOVA SENHA</label>
                    </span>
                </div>
            </div>

            <div className="box shadow-sm mb-5">
                <button className="btn btn-success me-2" type="button" onClick={() => salvar()}>Salvar</button>
            </div>
        </ColunaCentral>
        <Rodape/>
    </>
}

export default AlterarSenha;