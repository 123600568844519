import React, { useContext, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';
// import { Editor } from '@tinymce/tinymce-react';

import Api from '../../Api';
import { AuthContext } from '../../Contextos/Auth';
import BarraSuperior from '../../Componentes/BarraSuperior';
import Cabecalho from '../../Componentes/Cabecalho';
import ColunaCentral from '../../Componentes/ColunaCentral';
import IfComponent from '../../Componentes/IfComponent';
import Rodape from '../../Componentes/Rodape';
import EditorPett from '../../Componentes/EditorPett';

function Parametros() {
    const { logout } = useContext(AuthContext);
    const referenciaEditor01 = useRef(null);
    const referenciaEditor02 = useRef(null);
    const referenciaEditor03 = useRef(null);
    const referenciaEditor04 = useRef(null);
    const referenciaEditor05 = useRef(null);
    const referenciaEditor06 = useRef(null);
    const referenciaEditor07 = useRef(null);
    const referenciaEditor08 = useRef(null);
    const referenciaEditor09 = useRef(null);
    const referenciaEditor10 = useRef(null);
    const referenciaEditor11 = useRef(null);
    const referenciaEditor12 = useRef(null);
    const referenciaEditor13 = useRef(null);
    const referenciaEditor14 = useRef(null);
    const referenciaEditor15 = useRef(null);
    const referenciaEditor16 = useRef(null);
    const referenciaEditor17 = useRef(null);
    const referenciaEditor18 = useRef(null);
    const referenciaEditor19 = useRef(null);
    const [ parametros, setParametros ] = useState({ PAR_CODIGO: "0" });

    async function getParametros() {
        const formData = new FormData();
        formData.set('op','ver');
        const response = await Api.post('admin/parametros.php',formData);
        if (response.data.erro==='N') {
            setParametros(response.data.msg);
        }
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    // const getUnidades = useCallback(() => {
    //     const formData = new FormData();
    //     formData.set('op','combo');
    //     Api.post('unidades.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setUnidades(response.data.msg);
    //         }
    //     });
    // },[]);
    async function salvar() {
        const formData = new FormData();
        formData.set('op','atualizar');
        formData.set('PAR_FACEBOOK',parametros.PAR_FACEBOOK || '');
        formData.set('PAR_INSTAGRAM',parametros.PAR_INSTAGRAM || '');
        formData.set('PAR_MAILNAME',parametros.PAR_MAILNAME || '');
        formData.set('PAR_MAILFROM',parametros.PAR_MAILFROM || '');
        formData.set('PAR_MAILUSER',parametros.PAR_MAILUSER || '');
        formData.set('PAR_MAILPASS',parametros.PAR_MAILPASS || '');
        formData.set('PAR_MAILSMTP',parametros.PAR_MAILSMTP || '');
        formData.set('PAR_MAILPORT',parametros.PAR_MAILPORT || '0');
        formData.set('PAR_MAILADMIN',parametros.PAR_MAILADMIN || '');
        formData.set('PAR_MAILPREFIX',parametros.PAR_MAILPREFIX || '');
        formData.set('PAR_SITENAME',parametros.PAR_SITENAME || '');
        formData.set('PAR_WHATSAPP',parametros.PAR_WHATSAPP || '');
        formData.set('PAR_YOUTUBE',parametros.PAR_YOUTUBE || '');
        formData.set('PAR_VIDEOINICIAL1BR',parametros.PAR_VIDEOINICIAL1BR || '');
        formData.set('PAR_VIDEOINICIAL1EN',parametros.PAR_VIDEOINICIAL1EN || '');
        formData.set('PAR_VIDEOINICIAL1DE',parametros.PAR_VIDEOINICIAL1DE || '');
        formData.set('PAR_VIDEOINICIAL2BR',parametros.PAR_VIDEOINICIAL2BR || '');
        formData.set('PAR_VIDEOINICIAL2EN',parametros.PAR_VIDEOINICIAL2EN || '');
        formData.set('PAR_VIDEOINICIAL2DE',parametros.PAR_VIDEOINICIAL2DE || '');
        formData.set('PAR_EMAILALTERAREMAILBR',referenciaEditor08.current.getContent());
        formData.set('PAR_EMAILALTERAREMAILEN',referenciaEditor09.current.getContent());
        formData.set('PAR_EMAILALTERAREMAILDE',referenciaEditor10.current.getContent());
        formData.set('PAR_EMAILCONTATO',referenciaEditor01.current.getContent());
        formData.set('PAR_EMAILNOVOUSUARIOBR',referenciaEditor02.current.getContent());
        formData.set('PAR_EMAILNOVOUSUARIOEN',referenciaEditor03.current.getContent());
        formData.set('PAR_EMAILNOVOUSUARIODE',referenciaEditor04.current.getContent());
        formData.set('PAR_EMAILRESETARSENHABR',referenciaEditor05.current.getContent());
        formData.set('PAR_EMAILRESETARSENHAEN',referenciaEditor06.current.getContent());
        formData.set('PAR_EMAILRESETARSENHADE',referenciaEditor07.current.getContent());
        formData.set('PAR_EMAILREGPRODUTOBR',referenciaEditor17.current.getContent());
        formData.set('PAR_EMAILREGPRODUTOEN',referenciaEditor18.current.getContent());
        formData.set('PAR_EMAILREGPRODUTODE',referenciaEditor19.current.getContent());
        formData.set('PAR_EMAILSOLIDBR',referenciaEditor14.current.getContent());
        formData.set('PAR_EMAILSOLIDEN',referenciaEditor15.current.getContent());
        formData.set('PAR_EMAILSOLIDDE',referenciaEditor16.current.getContent());
        formData.set('PAR_EMAILUSUARIOCONFIRMADOBR',referenciaEditor11.current.getContent());
        formData.set('PAR_EMAILUSUARIOCONFIRMADOEN',referenciaEditor12.current.getContent());
        formData.set('PAR_EMAILUSUARIOCONFIRMADODE',referenciaEditor13.current.getContent());

        const response = await Api.post('/admin/parametros.php',formData);
        if (response.data.erro==='N') {
            setParametros(response.data.msg);
            Swal.fire({title: 'Yessss!', text: 'Parâmetros atualizados com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
        } else {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    useEffect(() => {
        getParametros();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="parametros") {
            setParametros({...parametros, [name]: valor});
        }
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" container="container">
            <Cabecalho titulo='Parâmetros' texto='&nbsp;'/>
            <IfComponent condicional={parametros.PAR_CODIGO!=="0"}>

                <div className="accordion mb-3" id="accordionExample">

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSite"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSite" aria-expanded="true" aria-controls="collapseSite">Geral</button></h2>
                        <div className="accordion-collapse collapse show" id="collapseSite" aria-labelledby="headingSite" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm pett-input">
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_SITENAME" value={parametros.PAR_SITENAME || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">TITULO DO SITE</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILADMIN" value={parametros.PAR_MAILADMIN || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">EMAIL DO ADMINISTRADOR</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILPREFIX" value={parametros.PAR_MAILPREFIX || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">PREFIXO DO ASSUNTO DO EMAIL</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_VIDEOINICIAL1BR" value={parametros.PAR_VIDEOINICIAL1BR || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="PAR_VIDEOINICIAL1BR">VIDEO INICIAL 1 BR</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_VIDEOINICIAL1EN" value={parametros.PAR_VIDEOINICIAL1EN || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="PAR_VIDEOINICIAL1EN">VIDEO INICIAL 1 EN</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_VIDEOINICIAL1DE" value={parametros.PAR_VIDEOINICIAL1DE || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="PAR_VIDEOINICIAL1DE">VIDEO INICIAL 1 DE</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_VIDEOINICIAL2BR" value={parametros.PAR_VIDEOINICIAL2BR || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="PAR_VIDEOINICIAL2BR">VIDEO INICIAL 2 BR</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_VIDEOINICIAL2EN" value={parametros.PAR_VIDEOINICIAL2EN || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="PAR_VIDEOINICIAL2EN">VIDEO INICIAL 2 EN</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_VIDEOINICIAL2DE" value={parametros.PAR_VIDEOINICIAL2DE || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="PAR_VIDEOINICIAL2DE">VIDEO INICIAL 2 DE</label>
                                        </span>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingRedes"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRedes" aria-expanded="true" aria-controls="collapseRedes">Redes Sociais</button></h2>
                        <div className="accordion-collapse collapse" id="collapseRedes" aria-labelledby="headingSite" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm pett-input">
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_FACEBOOK" value={parametros.PAR_FACEBOOK || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">FACEBOOK</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_INSTAGRAM" value={parametros.PAR_INSTAGRAM || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">INSTAGRAM</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_YOUTUBE" value={parametros.PAR_YOUTUBE || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">YOUTUBE</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_WHATSAPP" value={parametros.PAR_WHATSAPP || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">WHATSAPP</label>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingMail"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMail" aria-expanded="true" aria-controls="collapseMail">Configuração da conta de email</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseMail" aria-labelledby="headingMail" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm pett-input">
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILNAME" value={parametros.PAR_MAILNAME || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">NOME DO REMETENTE</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILFROM" value={parametros.PAR_MAILFROM || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">EMAIL DO REMETENTE</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILUSER" value={parametros.PAR_MAILUSER || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">LOGIN</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILPASS" value={parametros.PAR_MAILPASS || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">SENHA DO EMAIL</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILSMTP" value={parametros.PAR_MAILSMTP || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">SERVIDOR SMTP</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="10" name="PAR_MAILPORT" value={parametros.PAR_MAILPORT || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">PORTA</label>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingContato"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseContato" aria-expanded="true" aria-controls="collapseContato">Email Formulário de Contato</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseContato" aria-labelledby="headingContato" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <EditorPett referenciaEditor={referenciaEditor01} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILCONTATO} fileAcceptor="" label=""/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingNovoUsuario"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNovoUsuario" aria-expanded="true" aria-controls="collapseNovoUsuario">Email para o novo usuário</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseNovoUsuario" aria-labelledby="headingNovoUsuario" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm pett-input">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <EditorPett referenciaEditor={referenciaEditor02} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILNOVOUSUARIOBR} fileAcceptor="" label="Português"/>
                                        <EditorPett referenciaEditor={referenciaEditor03} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILNOVOUSUARIODE} fileAcceptor="" label="Inglês"/>
                                        <EditorPett referenciaEditor={referenciaEditor04} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILNOVOUSUARIODE} fileAcceptor="" label="Alemão"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingResetarSenha"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseResetarSenha" aria-expanded="true" aria-controls="collapseResetarSenha">Email Resetar Senha</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseResetarSenha" aria-labelledby="headingResetarSenha" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <EditorPett referenciaEditor={referenciaEditor05} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILRESETARSENHABR} fileAcceptor="" label="Português"/>
                                        <EditorPett referenciaEditor={referenciaEditor06} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILRESETARSENHAEN} fileAcceptor="" label="Inglês"/>
                                        <EditorPett referenciaEditor={referenciaEditor07} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILRESETARSENHADE} fileAcceptor="" label="Alemão"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingAlterarEmail"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAlterarEmail" aria-expanded="true" aria-controls="collapseAlterarEmail">Email Alterar Email</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseAlterarEmail" aria-labelledby="headingAlterarEmail" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <EditorPett referenciaEditor={referenciaEditor08} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILALTERAREMAILBR} fileAcceptor="" label="Português"/>
                                        <EditorPett referenciaEditor={referenciaEditor09} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILALTERAREMAILEN} fileAcceptor="" label="Inglês"/>
                                        <EditorPett referenciaEditor={referenciaEditor10} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILALTERAREMAILDE} fileAcceptor="" label="Alemão"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingUsuarioConfirmado"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUsuarioConfirmado" aria-expanded="true" aria-controls="collapseUsuarioConfirmado">Email Usuário Confirmado</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseUsuarioConfirmado" aria-labelledby="headingUsuarioConfirmado" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <EditorPett referenciaEditor={referenciaEditor11} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILUSUARIOCONFIRMADOBR} fileAcceptor="" label="Português"/>
                                        <EditorPett referenciaEditor={referenciaEditor12} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILUSUARIOCONFIRMADOEN} fileAcceptor="" label="Inglês"/>
                                        <EditorPett referenciaEditor={referenciaEditor13} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILUSUARIOCONFIRMADODE} fileAcceptor="" label="Alemão"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSolID"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSolID" aria-expanded="true" aria-controls="collapseSolID">Email do SOL ID</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseSolID" aria-labelledby="headingSolID" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <EditorPett referenciaEditor={referenciaEditor14} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILSOLIDBR} fileAcceptor="" label="Português"/>
                                        <EditorPett referenciaEditor={referenciaEditor15} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILSOLIDEN} fileAcceptor="" label="Inglês"/>
                                        <EditorPett referenciaEditor={referenciaEditor16} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILSOLIDDE} fileAcceptor="" label="Alemão"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingRegProduto"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRegProduto" aria-expanded="true" aria-controls="collapseRegProduto">Email do Registro de Produto</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseRegProduto" aria-labelledby="headingRegProduto" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <EditorPett referenciaEditor={referenciaEditor17} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILREGPRODUTOBR} fileAcceptor="" label="Português"/>
                                        <EditorPett referenciaEditor={referenciaEditor18} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILREGPRODUTOEN} fileAcceptor="" label="Inglês"/>
                                        <EditorPett referenciaEditor={referenciaEditor19} campoChave={parametros.PAR_CODIGO} campoTexto={parametros.PAR_EMAILREGPRODUTODE} fileAcceptor="" label="Alemão"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="box shadow-sm mt-3 mb-4">
                    <button className="btn btn-success me-2" onClick={() => salvar()}>Salvar</button>
                </div>
            </IfComponent>
            <IfComponent condicional={parametros.PAR_CODIGO==='0'}>
                <p className="lead">Não foi possível carregar os parâmetros!</p>
            </IfComponent>
        </ColunaCentral>
        <Rodape/>
    </>
}

export default Parametros;