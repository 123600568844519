import React from "react";
import { Link } from "react-router-dom";
import Flickity from 'react-flickity-component'
import "flickity/css/flickity.css";
import "./stylesflickity.css";

function BannersColunas(props) {
    const { banners, classeCell, autoPlay, groupCells } = props;
    const flickityOptions = {
        initialIndex: 2,
        wrapAround: true,
        // lazyLoad: true,
        groupCells: groupCells,
        // freeScroll: true,
        autoPlay: autoPlay
    }

    return <>
        <Flickity
            className={'carousel'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
        >
            { 
                banners.map((item,idx) =>(
                    <div className={'carousel-cell '+classeCell} key={idx} >
                        {/* <div className="col-6"> */}
                            <Link to={item.BAF_LINK}><img className="img-fluid" src={item.BAF_ARQUIVO} alt={item.BAF_CODIGO}/></Link>
                        {/* </div> */}
                    </div>
                ))
            }
        </Flickity>
        
        {/* <div className="carousel" data-flickity='{ "groupCells": true }'> */}
    </>
}

export default BannersColunas;