import React, { useState, useEffect } from 'react';

import IfComponent from './IfComponent';

function Menu(props) {
    const { data } = props;
    const [ menus, setMenus ] = useState([]);
    const location = window.location;

    const defaultOption =  {
        selector: "barramenu2",
        animation: false,
        animateIn: "animate__fadeIn"
    }
        
    //const bnOptions = { ...defaultOption, ...options };
        
    //q saco o warning que dá sem essa linha abaixo.
    //eslint-disable-next-line
    const bnOptions = { ...defaultOption }
        
    useEffect(() => {
        const dropdowns = document.getElementById(bnOptions.selector).getElementsByClassName("dropdown");

        Array.prototype.forEach.call(dropdowns, (item) => {

            //add animation
            if (bnOptions.animation) {
                const element = item.querySelector(".dropdown-menu");
                if (element) {
                    element.classList.add("animate__animated");
                    element.classList.add(bnOptions.animateIn);
                }
            }
            
            //hover effects
            item.addEventListener("mouseover", function () {
                this.classList.add("show");
                const element = this.querySelector(".dropdown-menu");
                if (element) {
                    element.classList.add("show");
                }
            });
            
            item.addEventListener("mouseout", function () {
                this.classList.remove("show");
                const element = this.querySelector(".dropdown-menu");
                if (element) {
                    element.classList.remove("show");
                }
            });
        });
    }, [bnOptions]);

    useEffect(() => {
        setMenus(data);
    }, [data]);
  
    return <>
        {/* <div className="navbar-collapse collapse" id="barramenu2"> */}
            <ul className="navbar-nav menu-font">
            {/* <ul className="navbar-nav" style={{fontFamily: "Roboto Condensed"}}> */}
                <IfComponent condicional={ menus.length > 0 }>                   
                    {                     
                        menus.map(menu => (
                            <React.Fragment key={ menu.MEN_CODIGO }>
                                <IfComponent condicional={ menu.MEN_DROP==="S" } >
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle d-flex align-items-center rounded" href={ menu.MEN_LINK } id={ 'MED'+menu.MEN_CODIGO } role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ menu.MEN_DESCRICAO }</a>
                                        <ul className="dropdown-menu font-reset" aria-labelledby={ 'MED'+menu.MEN_CODIGO }>
                                            <MenuFilhos data={ menu.MEN_FILHOS }/>
                                        </ul>                                    
                                    </li>
                                </IfComponent>
                                <IfComponent condicional={ menu.MEN_DROP==="N" } >
                                    <li>
                                        {/* <Link className={ location.pathname===menu.MEN_LINK?"nav-link active":"nav-link" } to={ menu.MEN_LINK } target={ menu.MEN_TARGET }>{ menu.MEN_DESCRICAO }</Link> */}
                                        <a className={ location.pathname===menu.MEN_LINK?"nav-link d-flex align-items-center rounded active":"nav-link rounded d-flex align-items-center" } href={ menu.MEN_LINK } target={ menu.MEN_TARGET }>{ menu.MEN_DESCRICAO }</a>
                                    </li>
                                </IfComponent>
                            </React.Fragment>
                        ))
                    }
                </IfComponent>
            </ul> 
        {/* </div> */}
    </>
}

function MenuFilhos(props) {
    const { data } = props;
    const location = window.location;
    return <>
        <IfComponent condicional={ data.length > 0 }>
            {
                data.map(menu => (
                    <React.Fragment key={ menu.MEN_CODIGO }>
                        <IfComponent condicional={ menu.MEN_DROP==="S" }>
                            <li className="nav-item dropdown">
                                <a className="dropdown-item dropdown-toggle pe-2" href={ menu.MEN_LINK } id={ 'MED'+menu.MEN_CODIGO } role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ menu.MEN_DESCRICAO }</a>
                                <ul className="dropdown-menu" aria-labelledby={ 'MED'+menu.MEN_CODIGO }>
                                    <MenuFilhos data={ menu.MEN_FILHOS }/>
                                </ul>                                    
                            </li>
                        </IfComponent>
                        <IfComponent condicional={ menu.MEN_DROP==="N" }>
                            <li>
                                {/* <Link className={ location.pathname===menu.MEN_LINK?"dropdown-item active":"dropdown-item" } to={ menu.MEN_LINK } target={ menu.MEN_TARGET }>{ menu.MEN_DESCRICAO }</Link> */}
                                <a className={ location.pathname===menu.MEN_LINK?"dropdown-item active":"dropdown-item" } href={ menu.MEN_LINK } target={ menu.MEN_TARGET }>{ menu.MEN_DESCRICAO }</a>
                            </li>
                        </IfComponent>
                    </React.Fragment>
                ))
            }
        </IfComponent>
    </>
}
   
export default Menu;