import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from "../Imagens/logo_sol_horizontal_color.svg";

function ErrorPage() {
    return <>
        <div className='bg-color2'>
            <div className='container bg-color2'>
                <div className="row d-flex justify-content-center align-items-center text-center vh-100">
                    <div className='col-12' style={{maxWidth: '550px'}}>
                        <Logo height="56"/>
                        {/* <img src='/imagens/logo_luppy_branco.svg' style={{maxWidth: '280px'}} alt='Luppy' /> */}
                        <p className="mt-5 lead">Xiiiiii... a página que você tentou acessar não existe aqui.</p>
                        <Link to='/' className="btn btn-lg btn-success w-100 mt-4" type="button">Voltar a página inicial</Link>
                    </div>
                </div>
            </div>    
        </div>    
    </>
}

export default ErrorPage;