import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { LinguaContext } from '../Contextos/Linguas';

function ComboLinguas() {
    const { idioma, setarIdioma } = useContext(LinguaContext);
    
    let imagem = '/imagens/flag_brazil2.png';
    if (idioma==='EN') imagem='/imagens/flag_great_britain2.png';
    if (idioma==='DE') imagem='/imagens/flag_germany2.png';
    if (idioma==='FR') imagem='/imagens/flag_france2.png';

    return <>
        <ul className="navbar-nav me-auto">
            <li className="nav-item dropdown dropdown-center p-2">
                <button className="btn btn-sm btn-light bg-transparent dropdown-toggle font-reset" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={imagem} alt={idioma} style={{maxWidth: '18px'}}/>
                </button>
                <ul className="dropdown-menu font-reset">
                    <li><Link className={'dropdown-item d-flex align-items-center '+(idioma==="BR"?'active disabled':'')} onClick={() => setarIdioma('BR')}><img src="/imagens/flag_brazil2.png" alt="" style={{maxWidth: '22px'}}/> Português</Link></li>
                    <li><Link className={'dropdown-item d-flex align-items-center '+(idioma==="EN"?'active disabled':'')} onClick={() => setarIdioma('EN')}><img src="/imagens/flag_great_britain2.png" alt="" style={{maxWidth: '22px'}}/> English</Link></li>
                    <li><Link className={'dropdown-item d-flex align-items-center '+(idioma==="DE"?'active disabled':'')} onClick={() => setarIdioma('DE')}><img src="/imagens/flag_germany2.png" alt="" style={{maxWidth: '22px'}}/> Deutsch</Link></li>
                    <li><a className="dropdown-item d-flex align-items-center" href="https://solfrance.fr"><img src="/imagens/flag_france2.png" alt="" style={{maxWidth: '22px'}}/> Français</a></li>
                </ul>
            </li>
        </ul>
        {/* <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href='#' id='LNG' role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{idioma}</a>
            <ul className="dropdown-menu font-reset" aria-labelledby='LNG'>
                <li><a className='dropdown-item' href='#' onClick={() => setarIdioma('BR')}><img src="/imagens/flag_brazil.png" alt="" /> Português</a></li>
                <li><a className='dropdown-item' href='#' onClick={() => setarIdioma('EN')}><img src="/imagens/flag_great_britain.png" alt="" /> English</a></li>
                <li><a className='dropdown-item' href='#' onClick={() => setarIdioma('DE')}><img src="/imagens/flag_germany.png" alt="" /> Deutsch</a></li>
                <li><a className='dropdown-item' href='https://solfrance.fr/'><img src="/imagens/flag_france.png" alt="" /> Français</a></li>
            </ul>                                    
        </li> */}
    </>
}

export default ComboLinguas;