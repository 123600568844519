import React, { useEffect, useState, createContext } from 'react';

export const LinguaContext = createContext();

export const LinguaProvider = ({children}) => {
    const [ idioma, setIdioma ] = useState('BR');

    const setarIdioma = (lingua) => {
        localStorage.setItem('idioma',lingua);
        setIdioma(lingua);
        window.location.reload();
    }

    useEffect(() => {
        const idiomaStorage = localStorage.getItem('idioma');
        if (idiomaStorage===null) {
            setIdioma("BR");
            localStorage.setItem('idioma',"BR");
        } else {
            setIdioma(idiomaStorage);
        }
    },[]);
    
    return (
        <LinguaContext.Provider value={{ idioma, setarIdioma }}>
            { children }
        </LinguaContext.Provider>
    )
}