import React from 'react';

function NoticiaCard(props) {
    return <>
        <div className="col" key={props.noticia.NOT_CODIGO}>
            <div className="card h-100 bg-transparent border-0">
                <a href={'/news/'+props.noticia.NOT_SLUG}><img className="card-img-top rounded-0" src={props.noticia.NOT_IMAGEMDESTAQUE} alt={props.noticia.NOT_CODIGO}/></a>
                <div className="card-body p-2">
                    <p className="text-secondary m-0"><span className="badge bg-secondary bg-opacity-10 text-secondary">{props.noticia.NOT_DATA}</span></p>
                    <h5 className="card-title"><a className="link-secondary" href={'/news/'+props.noticia.NOT_SLUG}>{props.noticia.NOT_TITULO}</a></h5>
                </div>
            </div>
        </div>
    </>
}

export default NoticiaCard;