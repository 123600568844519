
function T(idioma,texto) {
    if (texto==="Assistir...") { if (idioma==="BR") return texto; if (idioma==="EN") return "Watch..."; if (idioma==="DE") return "Teilnehmen"; }
    if (texto==="Cancelar") { if (idioma==="BR") return texto; if (idioma==="EN") return "Cancel"; if (idioma==="DE") return "Stornieren"; }
    if (texto==="NOME COMPLETO") { if (idioma==="BR") return texto; if (idioma==="EN") return "FULL NAME"; if (idioma==="DE") return "NAME"; }
    if (texto==="Enviar") { if (idioma==="BR") return texto; if (idioma==="EN") return "Send"; if (idioma==="DE") return "Senden"; }
    if (texto==="ENVIAR CORES") { if (idioma==="BR") return texto; if (idioma==="EN") return "SEND COLORS"; if (idioma==="DE") return "FARBEN SENDEN"; }
    if (texto==="Enviando...") { if (idioma==="BR") return texto; if (idioma==="EN") return "Sendind..."; if (idioma==="DE") return "Senden..."; }
    if (texto==="Envie sua cor personalizada!") { if (idioma==="BR") return texto; if (idioma==="EN") return "Send us your design, we need to validate it!"; if (idioma==="DE") return "Sende uns dein Design, wir müssen es prüfen."; }
    if (texto==="Preencha os campos abaixo para enviar a cor personalizada que você escolheu.") { if (idioma==="BR") return texto; if (idioma==="EN") return "Fill the fields below to send your customized colors."; if (idioma==="DE") return "Füllen Sie die Felder unten aus, um die von Ihnen gewählte benutzerdefinierte Farbe zu senden."; }
    if (texto==="Pedido finalizado com revendedor, somente escolha de cores") { if (idioma==="BR") return texto; if (idioma==="EN") return "Colors for an already confirmed order at my dealer."; if (idioma==="DE") return "Farbwahl einer Bestellung, die bei meinem Händler bereits bestätigt ist."; }
    if (texto==="Orçamento e quero retorno do setor de vendas") { if (idioma==="BR") return texto; if (idioma==="EN") return "Colors for quotation."; if (idioma==="DE") return "Farbwahl für Angebot"; }
    if (texto==="FINALIDADE") { if (idioma==="BR") return texto; if (idioma==="EN") return "PURPOSE"; if (idioma==="DE") return "ZWECK"; }
    if (texto==="TAMANHO") { if (idioma==="BR") return texto; if (idioma==="EN") return "SIZE"; if (idioma==="DE") return "GRÖβE"; }
    if (texto==="ESCOLA/INSTRUTOR") { if (idioma==="BR") return texto; if (idioma==="EN") return "SCHOOL/INSTRUCTOR"; if (idioma==="DE") return "HÄNDLER"; }
    if (texto==="FONE / WHATSAPP") { if (idioma==="BR") return texto; if (idioma==="EN") return "PHONE"; if (idioma==="DE") return "TELEFON"; }
    if (texto==="OBSERVAÇÕES") { if (idioma==="BR") return texto; if (idioma==="EN") return "OBSERVATIONS"; if (idioma==="DE") return "ANMERKUNGEN"; }
    if (texto==="Assine nossa newsletter preenchendo os campos abaixo.") { if (idioma==="BR") return texto; if (idioma==="EN") return "Stay up to date signing our newsletter."; if (idioma==="DE") return "Abonnieren Sie unseren Newsletter, indem Sie die folgenden Felder ausfüllen."; }
    if (texto==="Cadastre-me") { if (idioma==="BR") return texto; if (idioma==="EN") return "Sign me up"; if (idioma==="DE") return "Registriere mich"; }
    if (texto==="Revendedores no Mundo") { if (idioma==="BR") return texto; if (idioma==="EN") return "Dealers Worldwide"; if (idioma==="DE") return "Händler weltweit"; }
    if (texto==="Escolas no Brasil") { if (idioma==="BR") return texto; if (idioma==="EN") return "Schools in Brazil"; if (idioma==="DE") return "Schulen in Brasilien"; }
    if (texto==="Escolas de Paramotor no Brasil") { if (idioma==="BR") return texto; if (idioma==="EN") return "Paramotor Schools in Brazil"; if (idioma==="DE") return "Motorschirmschulen in Brasilien"; }
    if (texto==="SELECIONE O PAÍS") { if (idioma==="BR") return texto; if (idioma==="EN") return "SELECT THE COUNTRY"; if (idioma==="DE") return "WÄHLE DAS LAND"; }
    if (texto==="SELECIONE O ESTADO") { if (idioma==="BR") return texto; if (idioma==="EN") return "SELECT THE STATE"; if (idioma==="DE") return "WÄHLE SIE DEN STAAT AUS"; }
    if (texto==="Últimas Notícias") { if (idioma==="BR") return texto; if (idioma==="EN") return "Last News"; if (idioma==="DE") return "Neueste Nachrichten"; }
    if (texto==="Últimos Vídeos") { if (idioma==="BR") return texto; if (idioma==="EN") return "Last Videos"; if (idioma==="DE") return "Neueste Videos"; }
    if (texto==="Leia mais...") { if (idioma==="BR") return texto; if (idioma==="EN") return "Read more..."; if (idioma==="DE") return "Mehr lesen..."; }
    if (texto==="Ver mais notícias") { if (idioma==="BR") return texto; if (idioma==="EN") return "View more news"; if (idioma==="DE") return "Weitere Neuigkeiten sehen"; }
    if (texto==="Ver mais vídeos") { if (idioma==="BR") return texto; if (idioma==="EN") return "View more videos"; if (idioma==="DE") return "Sehen Sie mehr Videos"; }
    // if (texto==="") { if (idioma==="BR") return texto; if (idioma==="EN") return ""; if (idioma==="DE") return ""; }
    // if (texto==="") { if (idioma==="BR") return texto; if (idioma==="EN") return ""; if (idioma==="DE") return ""; }
}

export default T;