import React, { useState, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom'; 
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

// funcoes proprias.
import { AuthContext } from '../../Contextos/Auth';

function Login() {

    const { logado, usuarioStatus, mensagem, logar } = useContext(AuthContext);

    const [login, setLogin] = useState('');
    const [senha, setSenha] = useState('');
    const [acessando, setAcessando] = useState(false);

    if (usuarioStatus==='I') {
        return <Navigate to='/confirmar' />
    }

    if (logado) {
        return <Navigate to='/' />
    }

    async function Acessar() {     
        setAcessando(true);
        await logar(login, senha);      
        setAcessando(false);
    }

    return <div className="container-fluid bg-color2">
        <div className="d-flex align-items-center text-center form-login-container">
            <div className="form-login w-100">
                <a href="https://soldefense.com.br"><img src='/imagens/logo_sol_horizontal_color.svg' style={{maxWidth: '230px'}} className="mb-3" alt='SOL' /></a>
                { mensagem!=='' ? <div className="alert alert-danger mb-2" role="alert">{mensagem}</div> : null }
                <div className="row mt-3 gx-2 p-inputtext-lg pett-input">
                    <div className="col-12 mb-2">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="login" value={login || ''} onChange={(e) => setLogin(e.target.value)} required autoFocus/>
                            <label htmlFor="login">LOGIN</label>
                        </span>
                    </div>
                    <div className="col-12 mb-2">
                        <span className="p-float-label pett-label">
                            <Password className="w-100" name="senha" value={senha || ''} onChange={(e) => setSenha(e.target.value)} feedback={false} required />
                            <label htmlFor="senha">SENHA</label>
                        </span>
                    </div>
                </div>
                {   acessando
                        ? <button className="btn btn-lg btn-success w-100 my-4" type="button" disabled><i className='pi pi-fw pi-spin pi-spinner'></i> Verificando credenciais...</button>
                        : <button className="btn btn-lg btn-success w-100 my-4" type="button" onClick={Acessar}>Acessar</button>
                }
                <Link to="/reset">Esqueci a senha</Link>
            </div>
        </div>
    </div>
}

export default Login;