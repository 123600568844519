import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import CookieConsent from 'react-cookie-consent';

import { AuthProvider, AuthContext } from './Contextos/Auth';
import { LinguaProvider } from './Contextos/Linguas';
import { UnidadeProvider } from './Contextos/Unidades';

import Contato from './Paginas/Contato';
import Home from './Paginas/Home';
// import HomeParagliders from './Paginas/HomeParagliders';
// import CadastroClientes from './Paginas/CadastroClientes';
// import DownloadsList from './Paginas/DownloadsList';
// import DownloadsView from './Paginas/DownloadsView';
import ErrorPage from './Paginas/ErrorPage';
// import Escolas from './Paginas/Escolas';
// import EscolasParamotor from './Paginas/EscolasParamotor';
import News from './Paginas/News';
import NewsView from './Paginas/NewsView';
import Pagina from './Paginas/Pagina';
// import Produto from './Paginas/Produto';
// import ProdutosLista from './Paginas/ProdutosLista';
// import Revendedores from './Paginas/Revendedores';
import VideosList from './Paginas/VideosList';
import VideosView from './Paginas/VideosView';

//paginas do usuario.
import AlterarSenha from './Paginas/Usuario/AlterarSenha';
import Login from './Paginas/Usuario/Login';
import Profile from './Paginas/Usuario/Profile';
import ResetSenha from './Paginas/Usuario/Reset';

//paginas de parapentes.
// import Atmus3 from './Paginas/Parapentes/Atmus3';
// import Attak from './Paginas/Parapentes/Attak';
// import Austergt2 from './Paginas/Parapentes/Austergt2';
// import Cyclus1 from './Paginas/Parapentes/Cyclus1';
// import Cyclus2 from './Paginas/Parapentes/Cyclus2';
// import Effectxt from './Paginas/Parapentes/Effectxt';
// import Flexus2 from './Paginas/Parapentes/Flexus2';
// import Hercules2 from './Paginas/Parapentes/Hercules2';
// import Sycross2 from './Paginas/Parapentes/Sycross2';
// import Kangaroo4 from './Paginas/Parapentes/Kangaroo4';
// import Kuat3 from './Paginas/Parapentes/Kuat3';
// import Lt2 from './Paginas/Parapentes/Lt2';
// import Prymus5 from './Paginas/Parapentes/Prymus5';
// import Prymus6 from './Paginas/Parapentes/Prymus6';
// import Runnerxc from './Paginas/Parapentes/Runnerxc';
// import Sonic3d from './Paginas/Parapentes/Sonic3d';
// import Sonic3dx from './Paginas/Parapentes/Sonic3dx';
// import Speedup from './Paginas/Parapentes/Speedup';
// import Tr27 from './Paginas/Parapentes/Tr27';

//paginas do admin.
// import Cidades from './Paginas/Admin/Cidades';
// import Albuns from './Paginas/Admin/Albuns';
// import AlbunsFotos from './Paginas/Admin/AlbunsFotos';
// import Banners from './Paginas/Admin/Banners';
// import Categorias from './Paginas/Admin/Categorias';
// import Clientes from './Paginas/Admin/Clientes';
// import Contatos from './Paginas/Admin/Contatos';
// import Downloads from './Paginas/Admin/Downloads/Downloads';
// import DownloadsCategorias from './Paginas/Admin/Downloads/Categorias';
// import DownloadsLogs from './Paginas/Admin/Downloads/DownloadsLogs';
// import Familias from './Paginas/Admin/Familias';
// import Grupos from './Paginas/Admin/Grupos';
// import Leads from './Paginas/Admin/Leads';
// import Mensagens from './Paginas/Admin/Mensagens';
import MenuAdmin from './Paginas/Admin/Menu/MenuAdmin';
import MenuDrop from './Paginas/Admin/Menu/MenuDrop';
import MenuGestor from './Paginas/Admin/Menu/MenuGestor';
import MenuUsuario from './Paginas/Admin/Menu/MenuUsuario';
// import Noticias from './Paginas/Admin/Noticias';
// import NoticiasPreview from './Paginas/Admin/NoticiasPreview';
// import Paginas from './Paginas/Admin/Paginas';
// import PaginasHits from './Paginas/Admin/PaginasHits';
import Parametros from './Paginas/Admin/Parametros';
// import Produtos from './Paginas/Admin/Produtos';
// import ProdutosFotos from './Paginas/Admin/ProdutosFotos';
// import Registros from './Paginas/Admin/Registros';
// import Representantes from './Paginas/Admin/Representantes';
// import Unidades from './Paginas/Admin/Unidades';
// import Usuarios from './Paginas/Admin/Usuarios';
// import Videos from './Paginas/Admin/Videos';
// import VideosCat from './Paginas/Admin/VideosCat';

import "primereact/resources/themes/mdc-light-deeppurple/theme.css";  //theme
import "primereact/resources/primereact.min.css";                    //core css
import "primeicons/primeicons.css";                                  //icons
import "./App.css"; //pett

function App() {
    const Privado = ({ children }) => {
        const { logado, usuarioStatus } = useContext(AuthContext);
                
        if (usuarioStatus==='I') {
            return <Navigate to='/confirmarcadastro' />
        }
        
        if (!logado) {
            return <Navigate to="/login" replace={true}/>
        }
        
        return children;
    };

    const Admin = ({ children }) => {
        const { usuarioAdmin } = useContext(AuthContext);

        if (usuarioAdmin==="N") {
            return <Navigate to="/" replace={true} />
        }

        return children;
    }

    const Carregando = ({children}) => {
        const { carregando } = useContext(AuthContext);
        if (carregando) {
            return <>
                <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-spin fa-sync-alt"></i></span><br/>Carregando...</p>
                </div>  
            </>
        }
        return children;
    }

    return (
        <Router>
            <LinguaProvider>
                <UnidadeProvider>
                    <AuthProvider>
                        <Carregando>
                            <Routes>
                                <Route exact path='/' element={<Home />} /> 
                                {/* <Route exact path='/homeparagliders' element={<HomeParagliders />} />  */}
                                {/* <Route exact path='/contato' element={<Contato />} />  */}
                                <Route exact path='/login' element={<Login />} /> 
                                <Route exact path='/:id' element={<Pagina />} /> 
                                {/* <Route exact path='/cadastroclientes' element={<CadastroClientes />} />  */}
                                <Route exact path='/contato' element={<Contato />} /> 
                                {/* <Route exact path='/downloads' element={<DownloadsList />} /> 
                                <Route exact path='/downloads/:slug' element={<DownloadsView />} /> 
                                <Route exact path='/downloads/cat/:slug' element={<DownloadsList />} />  
                                <Route exact path='/escolas' element={<Escolas />} /> 
                                <Route exact path='/escolas/:id' element={<Escolas />} /> 
                                <Route exact path='/escolasparamotor' element={<EscolasParamotor />} /> 
                                <Route exact path='/escolasparamotor/:id' element={<EscolasParamotor />} />  */}
                                <Route exact path='/news' element={<News />} /> 
                                <Route exact path='/news/:slug' element={<NewsView />} /> 
                                <Route exact path='/news/cat/:cat' element={<News />} /> 
                                <Route exact path='/news/ano/:ano' element={<News />} /> 
                                <Route exact path='/news/ano/:ano/:mes' element={<News />} /> 
                                {/* <Route exact path='/parapentes/atmus3' element={<Atmus3 />} /> 
                                <Route exact path='/parapentes/attak' element={<Attak />} /> 
                                <Route exact path='/parapentes/austergt2' element={<Austergt2 />} /> 
                                <Route exact path='/parapentes/cyclus1' element={<Cyclus1 />} /> 
                                <Route exact path='/parapentes/cyclus2' element={<Cyclus2 />} /> 
                                <Route exact path='/parapentes/effectxt' element={<Effectxt />} /> 
                                <Route exact path='/parapentes/flexus2' element={<Flexus2 />} /> 
                                <Route exact path='/parapentes/hercules2' element={<Hercules2 />} /> 
                                <Route exact path='/parapentes/sycross2' element={<Sycross2 />} /> 
                                <Route exact path='/parapentes/kangaroo4' element={<Kangaroo4 />} /> 
                                <Route exact path='/parapentes/kuat3' element={<Kuat3 />} /> 
                                <Route exact path='/parapentes/lt2' element={<Lt2 />} /> 
                                <Route exact path='/parapentes/prymus5' element={<Prymus5 />} /> 
                                <Route exact path='/parapentes/prymus6' element={<Prymus6 />} /> 
                                <Route exact path='/parapentes/runnerxc' element={<Runnerxc />} /> 
                                <Route exact path='/parapentes/sonic3d' element={<Sonic3d />} /> 
                                <Route exact path='/parapentes/sonic3dx' element={<Sonic3dx />} /> 
                                <Route exact path='/parapentes/speedup' element={<Speedup />} /> 
                                <Route exact path='/parapentes/tr27' element={<Tr27 />} />  
                                <Route exact path='/produto/:id' element={<Produto />} /> 
                                <Route exact path='/produtos/' element={<ProdutosLista />} /> 
                                <Route exact path='/produtos/:familia' element={<ProdutosLista />} /> 
                                <Route exact path='/produtos/:familia/:grupo' element={<ProdutosLista />} />  */}
                                <Route exact path='/reset' element={<ResetSenha />} /> 
                                {/* <Route exact path='/revendedores' element={<Revendedores />} /> 
                                <Route exact path='/revendedores/:id' element={<Revendedores />} />  */}
                                <Route exact path='/videos/' element={<VideosList />} /> 
                                <Route exact path='/videos/:slug' element={<VideosView />} /> 
                                
                                <Route exact path='/alterarsenha' element={<Privado><AlterarSenha /></Privado>} /> 
                                <Route exact path='/profile' element={<Privado><Profile /></Privado>} /> 

                                {/* <Route exact path='/admin/albuns' element={<Privado><Admin><Albuns /></Admin></Privado>} /> 
                                <Route exact path='/admin/albunsfotos/:id' element={<Privado><Admin><AlbunsFotos /></Admin></Privado>} /> 
                                <Route exact path='/admin/banners' element={<Privado><Admin><Banners /></Admin></Privado>} /> 
                                <Route exact path='/admin/categorias' element={<Privado><Admin><Categorias /></Admin></Privado>} /> 
                                <Route exact path='/admin/clientes' element={<Privado><Admin><Clientes /></Admin></Privado>} /> 
                                <Route exact path='/admin/contatos' element={<Privado><Admin><Contatos /></Admin></Privado>} /> 
                                <Route exact path='/admin/downloads' element={<Privado><Admin><Downloads /></Admin></Privado>} /> 
                                <Route exact path='/admin/downloadscategorias' element={<Privado><Admin><DownloadsCategorias /></Admin></Privado>} /> 
                                <Route exact path='/admin/downloadslogs/:id' element={<Privado><Admin><DownloadsLogs /></Admin></Privado>} /> 
                                <Route exact path='/admin/familias' element={<Privado><Admin><Familias /></Admin></Privado>} /> 
                                <Route exact path='/admin/grupos' element={<Privado><Admin><Grupos /></Admin></Privado>} /> 
                                <Route exact path='/admin/leads' element={<Privado><Admin><Leads /></Admin></Privado>} /> 
                                <Route exact path='/admin/mensagens' element={<Privado><Admin><Mensagens /></Admin></Privado>} />  */}
                                <Route exact path='/admin/menuadmin' element={<Privado><Admin><MenuAdmin /></Admin></Privado>} /> 
                                <Route exact path='/admin/menudrop' element={<Privado><Admin><MenuDrop /></Admin></Privado>} /> 
                                <Route exact path='/admin/menugestor' element={<Privado><Admin><MenuGestor /></Admin></Privado>} /> 
                                <Route exact path='/admin/menuusuario' element={<Privado><Admin><MenuUsuario /></Admin></Privado>} /> 
                                {/* <Route exact path='/admin/noticias' element={<Privado><Admin><Noticias /></Admin></Privado>} /> 
                                <Route exact path='/admin/noticiaspreview/:slug' element={<Privado><Admin><NoticiasPreview /></Admin></Privado>} /> 
                                <Route exact path='/admin/paginas' element={<Privado><Admin><Paginas /></Admin></Privado>} /> 
                                <Route exact path='/admin/paginashits' element={<Privado><Admin><PaginasHits /></Admin></Privado>} /> 
                                <Route exact path='/admin/paginashits/:id' element={<Privado><Admin><PaginasHits /></Admin></Privado>} />  */}
                                <Route exact path='/admin/parametros' element={<Privado><Admin><Parametros /></Admin></Privado>} /> 
                                {/* <Route exact path='/admin/produtos' element={<Privado><Admin><Produtos /></Admin></Privado>} /> 
                                <Route exact path='/admin/produtosfotos/:id' element={<Privado><Admin><ProdutosFotos /></Admin></Privado>} /> 
                                <Route exact path='/admin/registros' element={<Privado><Admin><Registros /></Admin></Privado>} /> 
                                <Route exact path='/admin/representantes' element={<Privado><Admin><Representantes /></Admin></Privado>} /> 
                                <Route exact path='/admin/unidades' element={<Privado><Admin><Unidades /></Admin></Privado>} /> 
                                <Route exact path='/admin/usuarios' element={<Privado><Admin><Usuarios /></Admin></Privado>} /> 
                                <Route exact path='/admin/videos' element={<Privado><Admin><Videos /></Admin></Privado>} /> 
                                <Route exact path='/admin/videoscat' element={<Privado><Admin><VideosCat /></Admin></Privado>} />  */}

                                <Route path='*' element={<ErrorPage />} />
                            </Routes>
                        </Carregando>
                    </AuthProvider>
                </UnidadeProvider>
            </LinguaProvider>
            <CookieConsent
                location="bottom"
                buttonText="OK, Entendi!"
                cookieName="SOLCookieConsent"
                style={{ background: "#2b3035" }}
                buttonStyle={{ backgroundColor: "#198754", color: "#fff", fontSize: "15px", borderRadius: '6px' }}
                expires={7}
            >
                Utilizamos ferramentas e serviços de terceiros que utilizam cookies. <br/>Essas ferramentas nos ajudam a oferecer uma melhor experiência de navegação no site. <br/>Ao clicar no botão “OK, Entendi!” ou continuar a visualizar nosso site, você concorda com o uso de cookies em nosso site.
            </CookieConsent>
        </Router>

    );
}

export default App;