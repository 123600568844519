import React, { useCallback, useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';

import Api from '../Api';
import BarraSuperior from '../Componentes/BarraSuperior';
import Cabecalho from '../Componentes/Cabecalho';
import ColunaCentral from '../Componentes/ColunaCentral';
import Rodape from '../Componentes/Rodape';
import Bloco from '../Componentes/Bloco';

function Contato() {
    const [ token, setToken ] = useState(false);
    const [ contato, setContato ] = useState({ pais: '1058', destinatario: '3' });
    const [ destinatarios, setDestinatarios ] = useState([]);
    const [ paises, setPaises ] = useState([]);
    const [ enviando, setEnviando ] = useState(false);

    const getDestinatarios = useCallback((uf) => {
        const formData = new FormData();
        formData.set('op','combo');
        Api.post('contato.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setDestinatarios(response.data.msg);
            }
        });
    },[]); 

    const getPaises = useCallback((uf) => {
        const formData = new FormData();
        formData.set('op','combopaises');
        Api.post('enderecos.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setPaises(response.data.msg);
            }
        });
    },[]); 

    useEffect(() => {
        getDestinatarios();
        getPaises();
    },[getDestinatarios,getPaises]);

    async function enviar() {
        //const imagedata = document.querySelector('input[type="file"]').files[0];
        const imagedata = document.getElementById('arquivo').files[0];
        const formData = new FormData();
        formData.set('op','enviar');
        formData.set('assunto',contato.assunto);
        formData.set('cidade',contato.cidade);
        formData.set('destinatario',contato.destinatario);
        formData.set('email',contato.email);
        formData.set('estado',contato.estado);
        formData.set('fone',contato.fone);
        formData.set('instrutor',contato.instrutor);
        formData.set('mensagem',contato.mensagem);
        formData.set('nome',contato.nome);
        formData.set('pais',contato.pais);
        formData.set('referer',document.referrer);
        formData.set('serial',contato.serial);
        formData.set('arquivo',imagedata);
        try {
            setEnviando(true);
            const response = await Api.post('contato.php',formData);
            if (response.data.erro==='N') {
                Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                setContato({ pais: '1058', destinatario: '3' });
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
            setEnviando(false);
        } catch (error) {
            console.log('CATCH',error);
            setEnviando(false);
        }
    }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="contato") {
            setContato({...contato, [name]: valor});
        }
    }

    function verificarCaptcha(e) {
        setToken(true);
    }

    useEffect(() => {
        setToken(false);
    },[]);

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" container="container">
            <Cabecalho className="mb-3" titulo='Contato' texto=""/>
            <Bloco id="54" pagina="N"/>
            <div className="row mt-3 mb-3 gx-2 p-inputtext-sm pett-input">
                <div className="col-lg-6 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="nome" value={contato.nome || ''} onChange={(e) => setCampo(e,'contato',false)}/>
                        <label htmlFor="nome">NOME COMPLETO *</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="email" value={contato.email || ''} onChange={(e) => setCampo(e,'contato',false)}/>
                        <label htmlFor="email">EMAIL *</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="fone" value={contato.fone || ''} onChange={(e) => setCampo(e,'contato',false)}/>
                        <label htmlFor="fone">TELEFONE *</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <Dropdown className="w-100" name="pais" options={paises} value={String(contato.pais) || ''} onChange={(e) => setCampo(e,'contato',false)}/>
                        <label htmlFor="pais">PAÍS *</label>
                    </span>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="cidade" value={contato.cidade || ''} onChange={(e) => setCampo(e,'contato',false)}/>
                        <label htmlFor="cidade">CIDADE *</label>
                    </span>
                </div>
                <div className="col-lg-2 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="estado" value={contato.estado || ''} onChange={(e) => setCampo(e,'contato',false)}/>
                        <label htmlFor="estado">ESTADO *</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="serial" value={contato.serial || ''} onChange={(e) => setCampo(e,'contato',false)}/>
                        <label htmlFor="serial">SERIAL EQUIPAMENTO</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="instrutor" value={contato.instrutor || ''} onChange={(e) => setCampo(e,'contato',false)}/>
                        <label htmlFor="instrutor">INSTRUTOR/ESCOLA</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <Dropdown className="w-100" name="destinatario" options={destinatarios} value={String(contato.destinatario) || ''} onChange={(e) => setCampo(e,'contato',false)}/>
                        <label htmlFor="destinatario">DESTINATÁRIO *</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="assunto" value={contato.assunto || ''} onChange={(e) => setCampo(e,'contato',false)} required/>
                        <label htmlFor="assunto">ASSUNTO *</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 form-floating mb-2">
                    <span className="p-float-label pett-label">
                        <InputTextarea className="w-100" id="mensagem" name="mensagem" value={contato.mensagem} onChange={(e) => setCampo(e,'contato',false)} rows={10}/>
                        <label htmlFor="mensagem">MENSAGEM *</label>
                    </span>
                </div>
                <div className="col-12 mb-5" data-provides="fileinput">
                    <div className="input-group">
                        <input type="file" className="form-control form-floating" id="arquivo" name="arquivo" onChange={(e) => setCampo(e,'contato',false)} value={contato.arquivo}/>
                    </div>
                </div>  
                <div className="col-md-12 form-floating mb-2">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA} theme='light' onChange={(e) => verificarCaptcha(e)}/>
                </div>
            </div>

            <div className="box shadow-sm mb-5">
                {
                    enviando
                        ?<button className="btn btn-success disabled"><i className="fas fa-fw fa-spin fa-spinner"></i> Enviando...</button>
                        :<button className={token?"btn btn-success":"btn btn-success disabled"} onClick={() => enviar()}><i className="fas fa-fw fa-paper-plane"></i> Enviar</button>
                }
            </div>
            <Bloco id="83" pagina="N"/>
        </ColunaCentral>
        <Rodape/>
    </>
}

export default Contato;