import React, { useState } from 'react';

import IfComponent from './IfComponent';

function VideoWrapper(props) {
    const { video, imagem, titulo, descricao, className } = props;
    const [ play, setPlay ] = useState(false);

    function mostrarVideo(e) {
        e.preventDefault();
        setPlay(true);
    }

    return <>
        <IfComponent condicional={play===false}>
            <div className={'card text-bg-dark border-0 rounded-0 '+className}>
                <img className="card-img rounded-0" src={imagem} alt=""/>
                <div className="card-img-overlay d-flex align-items-end ms-md-5 ms-sm-1 mb-md-5 mb-sm-1">
                    <div className="card-title">
                        <h1 className="text-light">{titulo}</h1>
                        <div className="w-100">
                            <span className="fs-5" >{descricao}</span>
                        </div>
                    </div>
                </div>
                <div className="card-img-overlay">
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                        <div><a className="link-opacity-50 link-light opacity-50 stretched-link" href="\#" onClick={(e) => mostrarVideo(e)}><i className="fa fa-fw fa-6x fa-play-circle"></i></a></div>
                    </div>
                </div>
            </div>
        </IfComponent>

        <IfComponent condicional={play===true}>
            <div className={'video-responsive '+className}>
                <iframe width="853" height="480" src={'https://youtube.com/embed/'+video} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Embedded youtube"/>
            </div>
        </IfComponent>
    </>
}

export default VideoWrapper;