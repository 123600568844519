import React, { useEffect, useState, createContext } from 'react';
//import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
//import AsyncStorage from '@react-native-async-storage/async-storage';

import Api from '../Api';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
 
    //const navigate = useNavigate();
    const [usuario, setUsuario] = useState(null);
    const [usuarioAdmin, setUsuarioAdmin] = useState('N');
    const [usuarioGestor, setUsuarioGestor] = useState('N');
    const [usuarioAvatar, setUsuarioAvatar] = useState('');
    const [usuarioLogin, setUsuarioLogin] = useState('');
    const [usuarioNome, setUsuarioNome] = useState('');
    const [usuarioStatus, setUsuarioStatus] = useState('');
    const [logado, setLogado] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [carregando, setCarregando] = useState(true);

    useEffect(() => {
        const usuarioStorage = localStorage.getItem('usuario');
        const tokenStorage = localStorage.getItem('token');
        
        if (usuarioStorage && tokenStorage) {
            const jwtDecoded = jwt_decode(tokenStorage);

            // console.log(jwtDecoded.exp*1000)
            // console.log(Date.now())
            if (jwtDecoded.exp * 1000 < Date.now()) {
                Api.defaults.headers.Authorization = null;
                Api.defaults.headers.common['Authorization'] = null;
                localStorage.removeItem('usuario');
                localStorage.removeItem('token');
                setUsuario(null);
                setLogado(false);
                setUsuarioAdmin('N');
                setUsuarioGestor('N');
                setUsuarioAvatar('');
                setUsuarioLogin('');
                setUsuarioNome('');
                setUsuarioStatus('');
            } else {
                setUsuario(JSON.parse(usuarioStorage));
                setLogado(true);
                setUsuarioAdmin(jwtDecoded.admin);
                setUsuarioGestor(jwtDecoded.gestor);
                setUsuarioAvatar(jwtDecoded.avatar);
                setUsuarioLogin(jwtDecoded.login);
                setUsuarioNome(jwtDecoded.nome);
                setUsuarioStatus(jwtDecoded.status);
                Api.defaults.headers.common['Authorization'] = `Bearer ${tokenStorage}`;
            }
        } else {
            setLogado(false);
        }
        setCarregando(false);
    }, []);

    const setarToken = (response, login, senha) => {
        const token = response.data.usuario.USU_TOKEN;
        const admin = response.data.usuario.USU_ADMIN;
        const gestor = response.data.usuario.USU_GESTOR;
        const avatar = response.data.usuario.USU_AVATAR;
        //const login = response.data.usuario.USU_EMAIL;
        const nome = response.data.usuario.USU_NOME;
        const status = response.data.usuario.USU_STATUS;
        const jwtDecoded = jwt_decode(response.data.usuario.USU_TOKEN);
        localStorage.setItem('usuario', JSON.stringify({avatar, login, nome, status, admin, gestor }));
        localStorage.setItem('token',token);
        Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setUsuario({ login, nome, status  });
        setUsuarioLogin(jwtDecoded.login);
        setUsuarioNome(jwtDecoded.nome);
        setUsuarioStatus(jwtDecoded.status);
    }

    async function logar(login, senha) {
        if (!login || !senha) {
            setMensagem("Informe todos os campos.");
            return
        }
        var formData = new FormData();
        formData.set('op', 'login');
        formData.set('login', login);
        formData.set('senha', senha);

        try {
            const response = await Api.post('usuario/login.php',formData);
            if (response.data.erro==="S") {
                setMensagem(response.data.msg);
                setLogado(false);
                setUsuarioAdmin('N');
                setUsuarioGestor('N');
                setUsuarioAvatar('');
                setUsuarioLogin('');
                setUsuarioNome('');
                setUsuarioStatus('');
                localStorage.removeItem('usuario');
                localStorage.removeItem('token');
                //navigate('/login');
            } else {
                setarToken(response, login, senha);
                setLogado(true);
                setUsuarioAdmin(response.data.usuario.USU_ADMIN);
                setUsuarioGestor(response.data.usuario.USU_GESTOR);
                setUsuarioAvatar(response.data.usuario.USU_AVATAR);
                setUsuarioLogin(response.data.usuario.USU_EMAIL);
                setUsuarioNome(response.data.usuario.USU_NOME);
                setUsuarioStatus(response.data.usuario.USU_STATUS);
                setMensagem('');
                //navigate('/');
            }
        } catch (error) {
            console.log('Erro:',error)
        }
    };

    const logout = () => {
        Api.defaults.headers.Authorization = null;
        Api.defaults.headers.common['Authorization'] = null;
        localStorage.removeItem('usuario');
        localStorage.removeItem('token');
        setUsuario(null);
        setLogado(false);
        setUsuarioAdmin('N');
        setUsuarioGestor('N');
        setUsuarioAvatar('');
        setUsuarioLogin('');
        setUsuarioNome('');
        setUsuarioStatus('');
        //navigate('/login', { 'replace': true });
        window.location.reload();
    };

    return (
        // <AuthContext.Provider value={{logado: !!usuario, usuario, carregando, mensagem, logar, logout}}>
        <AuthContext.Provider value={{logado, usuario, usuarioAdmin, usuarioGestor, usuarioAvatar, usuarioLogin, usuarioNome, usuarioStatus, carregando, mensagem, logar, logout }}>
            { children }
        </AuthContext.Provider>
    )
}