import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
// import axios from 'axios';

function EditorPett(props) {

    // console.log(props);

    // const imageUploadHandler = async (blobInfo, progress) => {
    //     try {
    //         const tokenStorage = localStorage.getItem('token');
    //         const formData = new FormData();
    //         formData.append('file', blobInfo.blob(), blobInfo.filename());
    //         formData.append('op', 'inserir');
    //         formData.append('codigo', props.campoChave);
      
    //         const response = await axios.post(process.env.REACT_APP_URL_API + props.fileAcceptor, formData, {
    //             headers: {
    //                 'Authorization': `Bearer ${tokenStorage}`,
    //                 'Content-Type': 'multipart/form-data'
    //             },
    //             onUploadProgress: (progressEvent) => {
    //                 const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //                 progress(percentCompleted);
    //             }
    //         });
      
    //         if (response.status === 200) {
    //             const json = response.data;
    //             if (json.erro === 'S') {
    //                 throw json.msg;
    //             }
    //             return json.location;
    //         } else {
    //             throw `HTTP Error: ${response.status}`;
    //         }
    //     } catch (error) {
    //         if (error.response && error.response.status === 403) {
    //             throw { message: `HTTP Error: ${error.response.status} - ${error.response.statusText}`, remove: true };
    //         }
    //         throw `Falha no envio do arquivo. Erro: ${error.message}`;
    //     }
    // };
      
    // const imageUploadHandler = (blobInfo, progress) => new Promise((resolve, reject) => {       
    //     const tokenStorage = localStorage.getItem('token');

    //     const xhr = new XMLHttpRequest();
    //     xhr.withCredentials = false;
    //     // xhr.open('POST', process.env.REACT_APP_URL_API + '/admin/noticiaAcceptor.php');
    //     xhr.open('POST', process.env.REACT_APP_URL_API + props.fileAcceptor);
    //     xhr.setRequestHeader('Authorization', `Bearer ${tokenStorage}`);

    //     xhr.upload.onprogress = (e) => {
    //         progress(e.loaded / e.total * 100);
    //     };
      
    //     xhr.onload = () => {
    //         if (xhr.status === 403) {
    //             reject({ message: 'HTTP Error: ' + xhr.status + ' - ' + xhr.statusText, remove: true });
    //             return;
    //         }
      
    //         if (xhr.status !== 200) {
    //             reject('HTTP Error: ' + xhr.status);
    //             return;
    //         }
          
    //         const json = JSON.parse(xhr.responseText);
            
    //         if (json.erro==='S') {
    //             reject(json.msg);
    //             return;
    //         }

    //         resolve(json.location);
    //     };
      
    //     xhr.onerror = () => {
    //         reject('Falha no envio do arquivo. Erro: ' + xhr.status);
    //     };
      
    //     const formData = new FormData();
    //     formData.append('file', blobInfo.blob(), blobInfo.filename());
    //     formData.append('op','inserir');
    //     formData.append('codigo', props.campoChave);
      
    //     xhr.send(formData);
    // });

    return <>
        <div className="mb-3"><span className="ps-2">{props.label}</span>
            <Editor
                apiKey='ly6hr1bka7s3t7ud9o2omflzzdmrx4ydmpkvqns8rj5c4i6b'
                onInit={(evt, editor) => props.referenciaEditor.current = editor}
                //onInit={(evt, editor) => editorRef.current = editor} //passamos a referencia por props para ter acesso em paginas.jsx
                initialValue={props.campoTexto}
                init={{
                    paste_as_text: true,
                    relative_urls: false,
                    //remove_script_host: false,
                    //convert_urls: true, //https://www.tiny.cloud/docs/configure/url-handling/
                    document_base_url: 'https://soldefense.com.br',
                    // document_base_url: '',
                    
                    // images_upload_url: process.env.REACT_APP_URL_API + props.fileAcceptor,
                    // images_upload_handler: imageUploadHandler,
                    // images_upload_credentials: true,

                    // image_prepend_url: 'https://soldefense.com.br',
                    image_prepend_url: '',
                    //typeahead_urls: false,
                    object_resizing: true,
                    image_advtab: true,
                    image_dimensions: true,
                    image_title: true,
                    image_class_list: [
                        {title: 'None', value: ''},
                        {title: 'img-fluid', value: 'img-fluid'},
                        {title: 'img-thumbnail', value: 'img-thumbnail'},
                        {title: 'img-fuid img-responsive', value: 'img-fluid img-responsive'}
                    ],
                    style_formats: [
                        {title: 'Image Left', selector: 'img', styles: {
                            'float' : 'left',
                            'margin': '0 10px 0 10px'
                        }},
                        {title: 'Image Right', selector: 'img', styles: {
                            'float' : 'right',
                            'margin': '0 10px 0 10px'
                        }}
                    ],
                    //images_upload_base_path: '/some/basepath',
                    skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide' : 'oxide-dark'),
                    content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'default' : 'dark', '/styles.css'),
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: #fff; }',
                    height: 500,
                    menubar: 'file edit view insert format tools table help',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    // toolbar: 'code undo redo blocks ' +
                    //     'bold italic forecolor alignleft aligncenter ' +
                    //     'alignright alignjustify bullist numlist outdent indent ' +
                    //     'image removeformat help',
                        toolbar: 'code undo redo bold italic underline strikethrough fontfamily fontsize blocks alignleft aligncenter alignright alignjustify outdent indent numlist bullist forecolor backcolor removeformat image media template link anchor codesample ltr rtl',
                        // toolbar_sticky: true,
                        // toolbar_sticky_offset: 102,
                }}
            />  
        </div>          
    </>
}

export default EditorPett;