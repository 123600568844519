import React, { useContext, useState } from 'react';
import { InputText } from 'primereact/inputtext';

import { LinguaContext } from '../Contextos/Linguas';
import Api from '../Api';
import IfComponent from './IfComponent';
import T from '../Traducoes';

function NewsletterRodape() {
    const { idioma } = useContext(LinguaContext);
    const [ nome, setNome ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ msg, setMsg ] = useState('');

    async function cadastrarNewsletter() {
        const formData = new FormData();
        formData.set('op','subscribe');
        formData.set('nome',nome || '');
        formData.set('email',email || '');
        try {
            const response = await Api.post('subscribe.php',formData);
            setMsg(response.data.msg);
        } catch {
            console.log('CATCH Subscribe');
        }
    }

    return <>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 p-inputtext-sm pett-input text-center py-4">
                <p>{T(idioma,"Assine nossa newsletter preenchendo os campos abaixo")}</p>
                <div className="form-floating mb-3">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" type="text" maxLength="100" name="nome" value={nome} onChange={(e) => setNome(e.target.value)} />
                        {/* <InputText className="w-100" maxLength="100" name="nome" value={''} onChange={(e) => setCampo(e,'noticia',false)} /> */}
                        <label htmlFor="nome">{T(idioma,"NOME COMPLETO")}</label>
                    </span>
                </div>
                <div className="form-floating mb-3">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" type="email" maxLength="100" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <label htmlFor="email">EMAIL</label>
                    </span>
                </div>
                <IfComponent condicional={msg===""}>
                    <button className="btn btn-dark" name="cadastrar" onClick={() => cadastrarNewsletter()}>{T(idioma,"Cadastre-me")}</button>
                </IfComponent>
                <IfComponent condicional={msg!==""}>
                    <div className="alert alert-success p-2" role="alert">{msg}</div>
                </IfComponent>
            </div>
        </div>
    </>
}

export default NewsletterRodape;