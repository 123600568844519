import React, { useContext, useEffect, useState } from 'react';

import { LinguaContext } from '../Contextos/Linguas';
import { UnidadeContext } from '../Contextos/Unidades';
import Api from '../Api';
// import Banners from '../Componentes/Banners';
import BannersColunas from '../Componentes/BannersColunas';
import BarraSuperior from '../Componentes/BarraSuperior';
// import ColunaCentral from '../Componentes/ColunaCentral';
import Rodape from '../Componentes/Rodape';
import Bloco from '../Componentes/Bloco';
import NewsHorizontal from '../Componentes/NewsHorizontal';
// import Newsletter from '../Componentes/Newsletter';
import T from '../Traducoes';

function Home() {
    const { idioma } = useContext(LinguaContext);
    const { unidade } = useContext(UnidadeContext);
    const [ banners, setBanners ] = useState([]);

    async function getBanners(banner) {
        const formData = new FormData();
        formData.append('op','listar');
        formData.append('banner',banner);
        const response = await Api.post('banners.php',formData);
        if (response.data.erro==='N') {
            setBanners(response.data.msg);
        } else {
            console.log('Erro ao consultar os banners');
        }
    }

    useEffect(() => {
        getBanners(40);
        // eslint-disable-next-line
    },[unidade])
    
    return <>
        <BarraSuperior />
        <div className="space-top"></div>
        {/* <Banners banners={banners}/> */}
        <BannersColunas banners={banners} autoPlay={true} groupCells={false} classeCell=""/>

        <div className="container mt-4">
            <Bloco id={228} pagina="N" titulo={false} texto=""/>
            <h3 className="text-center">{T(idioma,"Últimas Notícias")}</h3>
            <NewsHorizontal/>
        </div>
        <Rodape/>
    </>
}

export default Home;