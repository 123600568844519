import React from 'react';
//import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// funcoes proprias.
import BarraSuperior from '../Componentes/BarraSuperior';
import Bloco from '../Componentes/Bloco';
import ColunaCentral from '../Componentes/ColunaCentral';
import Rodape from '../Componentes/Rodape';

function Pagina() {
    const { id } = useParams();

    return <>
        <BarraSuperior />
        <ColunaCentral left="false" right="false" container="container">
            {/* <section dangerouslySetInnerHTML={{ __html: bloco }} /> */}
            <Bloco id={ id } pagina="S" titulo={true} texto=""/>
        </ColunaCentral>
        <Rodape />
    </>
}

export default Pagina; 